
import IsNotOffer from './components/IsNotOffer'
import TheNav from '@/components/menu/components/TheNav'
import TheContacts from '@/components/menu/components/TheContacts'
import LeftMenuVerticalLines from '@/components/menu/components/LeftMenuVerticalLines'

export default {
  name: 'LeftMenu',
  components: {
    IsNotOffer,
    LeftMenuVerticalLines,
    TheContacts,
    TheNav,
  },

  data() {
    return {
      isMobileOpen: false,
      inputFocus: false,
      menuInputSearch: '',
      isHeadNavHidden: false,
      lastScrollPosition: 0,
      scrollValue: 0,
      OFFSET: 5,
    }
  },

  computed: {
    isMobile() {
      return this.$mq === 'sm'
    },
    background() {
      return this.inputFocus
        ? 'url("/header/nav/search-pink.svg")'
        : 'url("/header/search.svg")'
    },
  },

  watch: {
    isMobileOpen(val) {
      if (!val) {
        document.body.style.overflow = 'initial'
      }
    },
  },

  mounted() {
    this.lastScrollPosition = window.pageYOffset
    window.addEventListener('scroll', this.onScroll)
    const viewportMeta = document.createElement('meta')
    viewportMeta.name = 'viewport'
    viewportMeta.content = 'width=device-width, initial-scale=1'
    document.head.appendChild(viewportMeta)
  },

  methods: {
    menuOpenHandler() {
      this.isMobileOpen = !this.isMobileOpen
      this.isMobileOpen
        ? (document.body.style.overflow = 'hidden')
        : (document.body.style.overflow = 'initial')
    },
    onScroll() {
      if (window.pageYOffset < 0) {
        return
      }
      if (
        Math.abs(window.pageYOffset - this.lastScrollPosition) < this.OFFSET
      ) {
        return
      }

      this.isHeadNavHidden = window.pageYOffset > 20
      this.lastScrollPosition = window.pageYOffset
    },
    doSearch() {
      this.isMobileOpen = false
      this.$router.push(
        '/search?s=' +
          this.menuInputSearch.trim() +
          '&t=' +
          new Date().getTime()
      )
      this.menuInputSearch = ''
      this.inputFocus = false
    },
    goToMainPage() {
      this.isMobileOpen = false
      this.$router.push('/')
    },
  },
}

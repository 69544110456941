
export default {
  layout: 'MainPage',
  props: ['error'],
  head() {
    return {
      title: 'Ошибка 404',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: '',
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: '',
        },
      ],
    }
  },
}

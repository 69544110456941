
export default {
  name: 'Breadcrumbs',
  data() {
    return {
      // todo: необходимо переделать
      bcOptions: {
        '/about': [{ name: 'О компании', href: '', active: false }],
        '/shipment-pay': [
          { name: 'Оплата и доставка', href: '', active: false },
        ],
        '/genotyping': [{ name: 'Генотипирование', href: '', active: false }],
        '/genotyping/insider': [
          { name: 'Генотипирование', href: '/genotyping', active: true },
          { name: 'Инсайдер', href: '', active: false },
        ],
        '/genotyping/ras': [
          { name: 'Генотипирование', href: '/genotyping', active: true },
          { name: 'RAS каскад', href: '', active: false },
        ],
        '/genotyping/egfr': [
          { name: 'Генотипирование', href: '/genotyping', active: true },
          { name: 'Инсайдер EGFR', href: '', active: false },
        ],
        '/genotyping/brca': [
          { name: 'Генотипирование', href: '/genotyping', active: true },
          { name: 'ГенТест-М BRCA1, BRCA2', href: '', active: false },
        ],
        '/genotyping/cyp2c19': [
          { name: 'Генотипирование', href: '/genotyping', active: true },
          { name: 'ГенТест CYP2C19', href: '', active: false },
        ],
        '/genotyping/nat2': [
          { name: 'Генотипирование', href: '/genotyping', active: true },
          { name: 'ГенТест-М NAT2', href: '', active: false },
        ],
        '/genotyping/cyp2c9-vkorc1': [
          { name: 'Генотипирование', href: '/genotyping', active: true },
          { name: 'ГенТест CYP2C9, VKORC1, CYP4F2', href: '', active: false },
        ],
        '/genotyping/slco1b1': [
          { name: 'Генотипирование', href: '/genotyping', active: true },
          { name: 'ГенТест SLCO1B1', href: '', active: false },
        ],
        '/genotyping/f2-f5': [
          { name: 'Генотипирование', href: '/genotyping', active: true },
          { name: 'ГенТест F2, F5', href: '', active: false },
        ],
        '/genotyping/ces1': [
          { name: 'Генотипирование', href: '/genotyping', active: true },
          { name: 'ГенТест CES1', href: '', active: false },
        ],
        '/extraction': [{ name: 'Выделение ДНК', href: '', active: false }],
        '/extraction/dna2': [
          { name: 'Выделение ДНК', href: '/extraction', active: true },
          { name: 'ЭкстрактДНК-2', href: '', active: false },
        ],
        '/extraction/dna-ffpe': [
          { name: 'Выделение ДНК', href: '/extraction', active: true },
          { name: 'ЭкстрактДНК FFPE', href: '', active: false },
        ],
      },
    }
  },
  computed: {
    currentBC() {
      if (this.$route.path === '/') {
        return null
      }

      const r = this.$route.path.toLowerCase().replace(/\/+$/, '')

      if (Object.keys(this.bcOptionsNormalized).includes(r)) {
        return this.bcOptionsNormalized[r]
      }

      return null
    },
    bcOptionsNormalized() {
      return Object.keys(this.bcOptions).reduce((res, k) => {
        res[k.toLowerCase()] = this.bcOptions[k]
        return res
      }, {})
    },
  },
}


export default {
  name: 'NavLink',
  props: {
    isMobileOpen: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    iconWhite: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    id: {
      type: Number,
      default: 0,
    },
    activeLink: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    getIcon() {
      if (
        this.id === this.activeLink &&
        !this.isMobileOpen &&
        this.$mq === 'sm'
      ) {
        return `/header/nav/${this.iconWhite}`
      }
      return `/header/nav/${this.icon}`
    },
  },
}

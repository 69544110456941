
import GreenLinePopup from './components/GreenLinePopup'

export default {
  name: 'LeftMenuVerticalLines',
  components: { GreenLinePopup },

  data() {
    return {
      isPopupShow: false,
    }
  },

  methods: {
    handlePopupOpener() {
      this.isPopupShow = true
    },
    handlePopupCloser() {
      this.isPopupShow = false
    },
  },
}

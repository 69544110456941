
import Breadcrumbs from './components/Breadcrumbs'
import LeftMenu from '@/components/menu/LeftMenu'

export default {
  name: 'MainPage',
  components: {
    Breadcrumbs,
    LeftMenu,
  },
  data() {
    return {
      isReady: false,
    }
  },
  mounted() {
    // todo: воткнуто для борьбы с FOUT левого меню. нужно убрать, и привести в порядок стили. возможно к мельканию левого говна приводят манипуляции с window в mounted меню, в которое мне влом сейчас вникать
    this.$nextTick(() => {
      this.isReady = true
    })
  },
}

import axios from 'axios'

export const fetchUnits = (state, args) => {
  return axios({
    method: 'post',
    url: '/site/api/search',
    data: args,
    responseType: 'json',
  })
}


import NAV from '@/mock/header/nav.json'
import NavLink from '@/components/menu/components/TheNav/components/NavLink'

export default {
  name: 'TheNav',
  components: {
    NavLink,
  },
  props: {
    isMobileOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeLink: null,
    }
  },
  computed: {
    nav() {
      return NAV
    },
  },
  methods: {
    handleClick(event) {
      this.activeLink = event
      this.$emit('link-clicked')
    },
  },
}

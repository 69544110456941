
export default {
  name: 'ModalOffer',
  data() {
    return {
      modalIsVisible: false,
    }
  },
  methods: {
    closeOfferModal() {
      this.$store.commit('common/setOfferModalState', false)
    },
  },
}

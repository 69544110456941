export const TheMain = () => import('../../components/TheMain/index.vue' /* webpackChunkName: "components/the-main" */).then(c => wrapFunctional(c.default || c))
export const GeneralAppCardShowMore = () => import('../../components/general/AppCardShowMore.vue' /* webpackChunkName: "components/general-app-card-show-more" */).then(c => wrapFunctional(c.default || c))
export const GeneralAppPlusButton = () => import('../../components/general/AppPlusButton.vue' /* webpackChunkName: "components/general-app-plus-button" */).then(c => wrapFunctional(c.default || c))
export const GeneralAppProductCard = () => import('../../components/general/AppProductCard.vue' /* webpackChunkName: "components/general-app-product-card" */).then(c => wrapFunctional(c.default || c))
export const GeneralAppSearchInput = () => import('../../components/general/AppSearchInput.vue' /* webpackChunkName: "components/general-app-search-input" */).then(c => wrapFunctional(c.default || c))
export const GeneralAppSetsSection = () => import('../../components/general/AppSetsSection.vue' /* webpackChunkName: "components/general-app-sets-section" */).then(c => wrapFunctional(c.default || c))
export const GeneralAppUpButton = () => import('../../components/general/AppUpButton.vue' /* webpackChunkName: "components/general-app-up-button" */).then(c => wrapFunctional(c.default || c))
export const MenuLeftMenu = () => import('../../components/menu/LeftMenu.vue' /* webpackChunkName: "components/menu-left-menu" */).then(c => wrapFunctional(c.default || c))
export const TheMainComponentsContactCard = () => import('../../components/TheMain/components/ContactCard.vue' /* webpackChunkName: "components/the-main-components-contact-card" */).then(c => wrapFunctional(c.default || c))
export const TheMainComponentsContactsBlock = () => import('../../components/TheMain/components/ContactsBlock.vue' /* webpackChunkName: "components/the-main-components-contacts-block" */).then(c => wrapFunctional(c.default || c))
export const TheMainComponentsDistributors = () => import('../../components/TheMain/components/Distributors.vue' /* webpackChunkName: "components/the-main-components-distributors" */).then(c => wrapFunctional(c.default || c))
export const TheMainComponentsRoundedTable = () => import('../../components/TheMain/components/RoundedTable.vue' /* webpackChunkName: "components/the-main-components-rounded-table" */).then(c => wrapFunctional(c.default || c))
export const TheMainComponentsRoundedTableBRCA = () => import('../../components/TheMain/components/RoundedTableBRCA.vue' /* webpackChunkName: "components/the-main-components-rounded-table-b-r-c-a" */).then(c => wrapFunctional(c.default || c))
export const TheMainComponentsRoundedTableCES1 = () => import('../../components/TheMain/components/RoundedTableCES1.vue' /* webpackChunkName: "components/the-main-components-rounded-table-c-e-s1" */).then(c => wrapFunctional(c.default || c))
export const TheMainComponentsRoundedTableCYP2C19 = () => import('../../components/TheMain/components/RoundedTableCYP2C19.vue' /* webpackChunkName: "components/the-main-components-rounded-table-c-y-p2-c19" */).then(c => wrapFunctional(c.default || c))
export const TheMainComponentsRoundedTableEGFR = () => import('../../components/TheMain/components/RoundedTableEGFR.vue' /* webpackChunkName: "components/the-main-components-rounded-table-e-g-f-r" */).then(c => wrapFunctional(c.default || c))
export const TheMainComponentsRoundedTableF2F5 = () => import('../../components/TheMain/components/RoundedTableF2F5.vue' /* webpackChunkName: "components/the-main-components-rounded-table-f2-f5" */).then(c => wrapFunctional(c.default || c))
export const TheMainComponentsRoundedTableNAT2 = () => import('../../components/TheMain/components/RoundedTableNAT2.vue' /* webpackChunkName: "components/the-main-components-rounded-table-n-a-t2" */).then(c => wrapFunctional(c.default || c))
export const TheMainComponentsRoundedTableRAS = () => import('../../components/TheMain/components/RoundedTableRAS.vue' /* webpackChunkName: "components/the-main-components-rounded-table-r-a-s" */).then(c => wrapFunctional(c.default || c))
export const TheMainComponentsRoundedTableSLCO1B1 = () => import('../../components/TheMain/components/RoundedTableSLCO1B1.vue' /* webpackChunkName: "components/the-main-components-rounded-table-s-l-c-o1-b1" */).then(c => wrapFunctional(c.default || c))
export const TheMainComponentsRoundedTableSlide = () => import('../../components/TheMain/components/RoundedTableSlide.vue' /* webpackChunkName: "components/the-main-components-rounded-table-slide" */).then(c => wrapFunctional(c.default || c))
export const TheMainComponentsRoundedTableVKORC = () => import('../../components/TheMain/components/RoundedTableVKORC.vue' /* webpackChunkName: "components/the-main-components-rounded-table-v-k-o-r-c" */).then(c => wrapFunctional(c.default || c))
export const TheMainComponentsSertificateBlock = () => import('../../components/TheMain/components/SertificateBlock.vue' /* webpackChunkName: "components/the-main-components-sertificate-block" */).then(c => wrapFunctional(c.default || c))
export const TheMainComponentsSponsors = () => import('../../components/TheMain/components/Sponsors.vue' /* webpackChunkName: "components/the-main-components-sponsors" */).then(c => wrapFunctional(c.default || c))
export const TheMainComponentsTitleBlock = () => import('../../components/TheMain/components/TitleBlock.vue' /* webpackChunkName: "components/the-main-components-title-block" */).then(c => wrapFunctional(c.default || c))
export const MenuComponentsIsNotOffer = () => import('../../components/menu/components/IsNotOffer/index.vue' /* webpackChunkName: "components/menu-components-is-not-offer" */).then(c => wrapFunctional(c.default || c))
export const MenuComponentsLeftMenuVerticalLines = () => import('../../components/menu/components/LeftMenuVerticalLines/index.vue' /* webpackChunkName: "components/menu-components-left-menu-vertical-lines" */).then(c => wrapFunctional(c.default || c))
export const MenuComponentsTheContacts = () => import('../../components/menu/components/TheContacts/index.vue' /* webpackChunkName: "components/menu-components-the-contacts" */).then(c => wrapFunctional(c.default || c))
export const MenuComponentsTheNav = () => import('../../components/menu/components/TheNav/index.vue' /* webpackChunkName: "components/menu-components-the-nav" */).then(c => wrapFunctional(c.default || c))
export const MenuComponentsIsNotOfferComponentsModalOffer = () => import('../../components/menu/components/IsNotOffer/components/ModalOffer.vue' /* webpackChunkName: "components/menu-components-is-not-offer-components-modal-offer" */).then(c => wrapFunctional(c.default || c))
export const MenuComponentsLeftMenuVerticalLinesComponentsGreenLinePopup = () => import('../../components/menu/components/LeftMenuVerticalLines/components/GreenLinePopup.vue' /* webpackChunkName: "components/menu-components-left-menu-vertical-lines-components-green-line-popup" */).then(c => wrapFunctional(c.default || c))
export const MenuComponentsTheNavLink = () => import('../../components/menu/components/TheNav/components/NavLink.vue' /* webpackChunkName: "components/menu-components-the-nav-link" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}

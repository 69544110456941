
import CONTACTS from '@/mock/header/contacts.json'

export default {
  name: 'TheContacts',
  computed: {
    contacts() {
      return CONTACTS
    },
  },
}


import ModalOffer from './components/ModalOffer'
export default {
  name: 'IsNotOffer',
  components: {
    ModalOffer,
  },
  computed: {
    modalIsVisible() {
      return this.$store.state.common.offerModalIsOpen
    },
  },
  methods: {
    openOfferModal() {
      this.$store.commit('common/setOfferModalState', true)
    },
  },
}

import Vue from 'vue'
import * as Sentry from '@sentry/vue'

import { Integrations } from '@sentry/tracing'

if (process.env.NODE_ENV === 'production') {
  try {
    Sentry.init({
      Vue,
      dsn: 'https://f4fc30a4655140ed8caba1d192f1829c@o1043899.ingest.sentry.io/6031361',
      integrations: [
        new Integrations.BrowserTracing({
          tracingOrigins: ['localhost', 'nomotech.ru', /^\//],
        }),
      ],
      tracesSampleRate: 1.0,
      trackComponents: true,
      hooks: ['create', 'mount', 'update'],
    })
  } catch (e) {}
}
